<template>
  <section class="transfer">
    <h2 class="heading">Bills Categories</h2>
    <div
      class="trasactions__container"
      v-loading="loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
    >
      <section
        v-for="category in allCategories"
        :key="category.categoryid"
        class="category"
        @click="saveCategoryDetails(category)"
      >
        <div class="category__left">
          <h2 class="category__name">{{ category.name }}</h2>
          <h3 class="category__type">{{ category.description }}</h3>
        </div>
        <div class="category__right">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            aria-hidden="true"
            focusable="false"
            style="-ms-transform: rotate(360deg); -webkit-transform: rotate(360deg); transform: rotate(360deg);"
            preserveAspectRatio="xMidYMid meet"
            viewBox="0 0 24 24"
          >
            <path
              d="M5.536 21.886a1.004 1.004 0 0 0 1.033-.064l13-9a1 1 0 0 0 0-1.644l-13-9A1 1 0 0 0 5 3v18a1 1 0 0 0 .536.886z"
              fill="#db353a"
            />
            <rect x="0" y="0" width="24" height="24" fill="rgba(0, 0, 0, 0)" />
          </svg>
        </div>
      </section>
    </div>
  </section>
</template>

<script>
import { mapState } from "vuex";
export default {
  computed: mapState(["userAccounts", "user", "allCategories"]),
  methods: {
    saveCategoryDetails(details) {
      this.$store.dispatch("setCategoryDetails", details);
      this.$router.push("/dashboard/bills/category");
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_form.scss";
</style>
